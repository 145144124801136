import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Parallax, Background } from 'react-parallax';
import { FaHeartbeat } from 'react-icons/fa';

import SlideImageUno from '../path/to/cuidando_lo_mas_valioso.jpeg';
import SlideImageDos from '../path/to/bienestar.jpeg';
import SlideImageTres from '../path/to/DJI_0630-HDR-1_baja.jpg';
import SlideImageCuatro from '../path/to/compromiso.jpeg';

import ThirtyBackgroundImage from '../path/to/medicalassistant.jpg';

// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import '../styles/swiper.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const PrincipalPage = () => {
    const { t } = useTranslation();

    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // init Swiper
    const progressCircle = document.querySelector('.autoplay-progress svg');
    const progressContent = document.querySelector('.autoplay-progress span');
    // eslint-disable-next-line
    const swiper = new Swiper('.swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        autoplay: {
            delay: 8000,
        },
        on: {
            autoplayTimeLeft(s, time, progress) {
                progressCircle.style.setProperty('--progress', 1 - progress);
                progressContent.textContent = `${Math.ceil(time / 1000)}s`;
            },
        },
    });

    return (
        <>
            <div className='flex h-[45rem] transform overflow-hidden transition-all duration-700'>
                {/* Carousel */}
                <div className='swiper'>
                    {/* <!-- Additional required wrapper --> */}
                    <div className='swiper-wrapper'>
                        {/* <!-- Slides --> */}
                        <div className='swiper-slide'>
                            <Parallax strength={300}>
                                <Background className='custom-bg'>
                                    <div
                                        style={{
                                            height: 800,
                                            width: 1600,
                                            backgroundImage: `url(${SlideImageUno})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </Background>
                                <div className='relative flex h-[45rem] items-center justify-center bg-cover'>
                                    <div className='mx-80 rounded-lg border border-black bg-black bg-opacity-50 p-4'>
                                        <h1 className='mb-4 text-center text-2xl capitalize text-white'>{t('slider-seccion-uno')}</h1>
                                        <p className='text-justify text-lg leading-loose text-white'>{t('slider-seccion-uno-descripcion')}</p>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                        <div className='swiper-slide'>
                            <Parallax strength={300}>
                                <Background className='custom-bg'>
                                    <div
                                        style={{
                                            height: 800,
                                            width: 1600,
                                            backgroundImage: `url(${SlideImageDos})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </Background>
                                <div className='relative flex h-[45rem] items-center justify-center bg-cover'>
                                    <div className='mx-80 rounded-lg border border-black bg-black bg-opacity-50 p-4'>
                                        <h1 className='mb-4 text-center text-2xl capitalize text-white'>{t('slider-seccion-dos')}</h1>
                                        <p className='text-justify text-lg leading-loose text-white'>{t('slider-seccion-dos-descripcion')}</p>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                        <div className='swiper-slide'>
                            <Parallax strength={300}>
                                <Background className='custom-bg'>
                                    <div
                                        style={{
                                            height: 800,
                                            width: 1600,
                                            backgroundImage: `url(${SlideImageTres})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </Background>
                                <div className='relative flex h-[45rem] items-center justify-center bg-cover'>
                                    <div className='mx-80 rounded-lg border border-black bg-black bg-opacity-50 p-4'>
                                        <h1 className='mb-4 text-center text-2xl capitalize text-white'>{t('slider-seccion-tres')}</h1>
                                        <p className='text-justify text-lg leading-loose text-white'>{t('slider-seccion-tres-descripcion')}</p>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                        <div className='swiper-slide'>
                            <Parallax strength={300}>
                                <Background className='custom-bg'>
                                    <div
                                        style={{
                                            height: 800,
                                            width: 1600,
                                            backgroundImage: `url(${SlideImageCuatro})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            justifyContent: 'center',
                                        }}
                                    />
                                </Background>
                                <div className='relative flex h-[45rem] items-center justify-center bg-cover'>
                                    <div className='mx-80 rounded-lg border border-black bg-black bg-opacity-50 p-4'>
                                        <h1 className='mb-4 text-center text-2xl capitalize text-white'>{t('slider-seccion-cuatro')}</h1>
                                        <p className='text-justify text-lg leading-loose text-white'>{t('slider-seccion-cuatro-descripcion')}</p>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                    </div>

                    {/* <!-- If we need navigation buttons --> */}
                    <div className='swiper-button-prev'></div>
                    <div className='swiper-button-next'></div>
                    <div className='autoplay-progress'>
                        <svg viewBox='0 0 48 48'>
                            <circle cx='24' cy='24' r='20'></circle>
                        </svg>
                        <span></span>
                    </div>
                </div>
            </div>
            {/* Primera sección */}
            <div style={{ height: 600 }}>
                <div className='relative flex h-full flex-col items-center justify-center bg-white bg-opacity-40 p-20'>
                    <h1 className='mb-4 text-4xl font-semibold'>{t('bienvenida')}</h1>
                    <p className='mb-20 px-20 py-4 text-justify text-2xl'>{t('mensaje_bienvenida_1')}</p>
                    <p className='px-20 py-4 text-justify text-2xl'>{t('mensaje_bienvenida_2')}</p>
                    <p className='px-20 py-4 text-justify text-2xl'>{t('mensaje_bienvenida_3')}</p>
                </div>
            </div>
            <Parallax strength={300}>
                <Background className='custom-bg'>
                    <div
                        style={{
                            height: 1200,
                            width: 2000,
                            backgroundImage: `url(${ThirtyBackgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            justifyContent: 'center',
                        }}
                    />
                </Background>
                <div className='relative flex min-h-screen items-center justify-center bg-cover'>
                    <div className='mx-20 flex w-full divide-x-2 divide-gray-400 rounded-3xl bg-black bg-opacity-50'>
                        <div className='flex-1 rounded-lg p-10'>
                            <h1 className='mb-8 text-center text-3xl capitalize text-white'>{t('mensaje_contacto_titulo')}</h1>
                            <p className='text-1xl leading-2 text-justify leading-loose text-white'>{t('mensaje_contacto_descripcion')}</p>
                        </div>
                        <div className='flex flex-1 flex-col items-center justify-between self-center p-4'>
                            <div>
                                <h2 className='mb-4 text-center text-2xl text-white'>{t('contactanos_titulo')}</h2>
                                <div className='mb-4 text-lg leading-loose text-white'>
                                    <p>
                                        <a href={t('contactanos_telefono_numero')} className='hover:text-green-300'>
                                            {t('contactanos_telefono')}
                                        </a>
                                    </p>
                                    <hr className='border-t border-white' />
                                    <p>
                                        <a href={t('contactanos_whatsapp_link')} target='_blank' rel='noopener noreferrer' className='hover:text-green-300'>
                                            {t('contactanos_whatsapp')}
                                        </a>
                                    </p>
                                    <hr className='border-t border-white' />
                                    <p>
                                        <a href={t('contactanos_email_correo')} className='hover:text-green-300'>
                                            {t('contactanos_email')}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
            <div style={{ height: 50 }}>
                <div className='flex h-full  items-center justify-center bg-white'>
                    <p className='px-2 text-justify'>© Copyright 2024</p>|<p className='px-2 text-justify'>All Rights Reserved</p>
                    Hecha con
                    <FaHeartbeat className='corazon mx-2 self-center' />
                    <a className='self-center' href='https://abc-ti.com' target='_blank'>
                        Abel Castillo
                    </a>
                    {/* |<p className='text-justify px-2'>Web Designed by Abel Castillo.</p> */}
                    {/* <a href="https://www.freepik.es/foto-gratis/doctora-sentado-mostrando-signo-ok_6403672.htm#fromView=search&page=1&position=4&uuid=bd550dfd-e842-4715-9a19-fa0322b51005">Imagen de freepik</a> */}
                </div>
            </div>
        </>
    );
};

export default PrincipalPage;
