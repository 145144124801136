import React from 'react';
import { useTranslation } from 'react-i18next';
import { Parallax, Background } from 'react-parallax';
import { FaHeartbeat } from 'react-icons/fa';

import HeroBackgroundImage from '../path/to/DJI_0630-HDR-1_baja.jpg';
import InsuranceImage from '../path/to/medicaltourism.jpg';
import MedicalTourismImage from '../path/to/medicalasegurance.jpg';
import EducationImage from '../path/to/hombre-negocios-feliz-dando-presentacion-grupo-colegas-medicos.jpg';

const TurismoMedicoPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Parallax bgImage={HeroBackgroundImage} strength={300} style={{ height: '35rem', backgroundSize: 'cover' }}>
                <div className='flex h-full items-center justify-center'>
                    <div className='rounded-lg border border-white bg-white bg-opacity-50 p-2'>
                        <h1 className='text-center text-5xl font-black capitalize text-gray-900'>{t('turismo_medico_titulo')}</h1>
                    </div>
                </div>
            </Parallax>
            <div className='my-10' />
            <div className='flex items-stretch justify-between'>
                <div className='flex w-2/3 flex-col justify-between bg-white px-10 py-6'>
                    <h1 className='mb-4 text-2xl text-blue-800'>{t('turismo_medico_seccion_uno_titulo')}</h1>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_uno_descripcion_uno')}</p>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_uno_descripcion_dos')}</p>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_uno_descripcion_tres')}</p>
                </div>
                <Parallax strength={300}>
                    <Background className='custom-bg'>
                        <div
                            style={{
                                height: 800,
                                width: 800,
                                backgroundImage: `url(${InsuranceImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                justifyContent: 'center',
                            }}
                        />
                    </Background>
                    <div className='bg-white px-96'></div>
                </Parallax>
            </div>
            <div className='my-10' />
            <div className='flex items-stretch justify-between'>
                <Parallax strength={300}>
                    <Background className='custom-bg'>
                        <div
                            style={{
                                height: 1000,
                                width: 1000,
                                backgroundImage: `url(${MedicalTourismImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                justifyContent: 'center',
                            }}
                        />
                    </Background>
                    <div className='bg-white px-96'></div>
                </Parallax>
                <div className='flex w-1/2 flex-col justify-between bg-white p-6'>
                    <h1 className='mb-4 text-2xl text-blue-800'>{t('turismo_medico_seccion_dos_titulo')}</h1>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_dos_descripcion_uno')}</p>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_dos_descripcion_dos')}</p>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_dos_descripcion_tres')}</p>
                </div>
            </div>
            <div className='my-10' />
            <div className='flex items-stretch justify-between'>
                <div className='flex w-1/2 flex-col justify-between bg-white p-6'>
                    <h1 className='mb-4 text-2xl text-blue-800'>{t('turismo_medico_seccion_tres_titulo')}</h1>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_tres_descripcion_uno')}</p>
                    <p className='mb-2 text-justify text-lg leading-loose'>{t('turismo_medico_seccion_tres_descripcion_dos')}</p>
                </div>
                <Parallax strength={300}>
                    <Background className='custom-bg'>
                        <div
                            style={{
                                height: 800,
                                width: 800,
                                backgroundImage: `url(${EducationImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                justifyContent: 'center',
                            }}
                        />
                    </Background>
                    <div className='bg-white px-96'></div>
                </Parallax>
            </div>
            <div style={{ height: '50px', marginTop: '10px' }}>
                <div className='flex h-full items-center justify-center bg-white'>
                    <p className='px-2 text-justify'>© Copyright 2024</p>
                    <p className='px-2 text-justify'>All Rights Reserved</p>
                    Hecha con
                    <FaHeartbeat className='corazon mx-2 self-center' />
                    <a className='self-center' href='https://abc-ti.com' target='_blank'>
                        Abel Castillo
                    </a>
                    {/* Imagen de <a href="https://www.freepik.es/foto-gratis/estetoscopio-alto-angulo-mapa_8096676.htm#fromView=search&page=1&position=5&uuid=510fc3f0-391a-4055-bf29-499aa7c8253a">Freepik</a> */}
                    {/* <a href="https://www.freepik.es/foto-gratis/medico-vista-frontal-elemento-medico_12412966.htm#fromView=search&page=1&position=1&uuid=fb22afc3-69f5-4de5-92b4-6036eb048bd8">Imagen de freepik</a> */}
                    {/* https://www.freepik.es/foto-gratis/hombre-negocios-feliz-dando-presentacion-grupo-colegas-medicos_26607660.htm#fromView=search&page=1&position=13&uuid=37b9317b-4c1f-4c02-a895-89c2c67218ba */}
                </div>
            </div>
        </>
    );
};

export default TurismoMedicoPage;
