import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import esTranslations from './traducciones/es/translation.json';
import enTranslations from './traducciones/en/translation.json';

// Configuración de i18n
i18n.use(initReactI18next) // pasa i18n hacia abajo a react-i18next
    .use(LanguageDetector) // agrega el detector de idioma
    .init({
        resources: {
            es: {
                translation: esTranslations,
            },
            en: {
                translation: enTranslations,
            },
        },
        fallbackLng: 'es', // Lenguaje de fallback
        detection: {
            // Configuración para el detector de idioma
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'], // Donde almacenar la selección del idioma
        },
        interpolation: {
            escapeValue: false, // no necesita escapar de XSS
        },
    });

export default i18n;
