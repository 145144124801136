import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import especialidadesData from '../traducciones/en/doctors';
import { FaHeartbeat } from 'react-icons/fa';

const NuestrosMedicosPage = () => {
    const { t } = useTranslation();
    const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState(null);

    const seleccionarEspecialidad = (nombre) => {
        setEspecialidadSeleccionada(especialidadSeleccionada === nombre ? null : nombre);
    };

    return (
        <>
            <div className='min-h-screen bg-white px-10 pt-40 text-gray-800'>
                <h1 className='mb-10 text-center text-2xl font-bold'>{t('conoce_nuestros_medicos')}</h1>
                <div>
                    {especialidadesData.map((especialidad) => (
                        <div key={especialidad.nombre} className='mb-8'>
                            <div
                                onClick={() => seleccionarEspecialidad(especialidad.nombre)}
                                className='mb-4 flex w-full cursor-pointer items-center justify-between rounded-lg bg-blue-500 px-4 py-2 text-left font-semibold text-white shadow-md transition-colors hover:bg-blue-700'>
                                <span>{especialidad.nombre.toUpperCase()}</span>
                                <span
                                    className={`transform transition-transform ${especialidadSeleccionada === especialidad.nombre ? 'rotate-180' : 'rotate-0'}`}>
                                    <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                                        <path
                                            fillRule='evenodd'
                                            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                className={`${especialidadSeleccionada === especialidad.nombre ? 'max-h-full' : 'max-h-0'} transition-max-height overflow-hidden duration-700 ease-in-out`}>
                                <div className='mt-4'>
                                    {especialidad.medicos.map((medico) => (
                                        <div
                                            key={medico.nombre}
                                            className='mb-4 flex flex-col items-center rounded-lg bg-blue-50 p-4 shadow-lg md:flex-row md:items-start md:justify-between'>
                                                {console.log(medico)}
                                            <img src={medico.foto} alt={medico.nombre} className='mx-auto mb-4 h-48 w-48 rounded-full md:mx-0 md:mb-0' />
                                            <div className='px-2 text-center md:text-left'>
                                                <p className='text-lg font-semibold'>{medico.nombre}</p>
                                                <p className='text-sm text-gray-600'>{medico.especialidad}</p>
                                            </div>
                                            <div className='px-2 text-center md:flex-grow md:text-left'>
                                                <p>Experiencia: {medico.experiencia} años</p>
                                                <p>Educación: {medico.educacion}</p>
                                                <p>Áreas de especialización: {medico.especializaciones.join(', ')}</p>
                                                <p>Logros destacados: {medico.logros}</p>
                                            </div>
                                            <div className='text-center italic md:text-right'>"Excelente atención y profesionalismo" - Paciente satisfecho</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <p>{t('conoce_nuestros_medicos_leyenda')}</p>
                </div>
            </div>
            <div style={{ height: 50 }}>
                <div className='flex h-full  items-center justify-center bg-white'>
                    <p className='px-2 text-justify'>© Copyright 2024</p>|<p className='px-2 text-justify'>All Rights Reserved</p>
                    Hecha con
                    <FaHeartbeat className='corazon mx-2 self-center' />
                    <a className='self-center' href='https://abc-ti.com' target='_blank'>
                        Abel Castillo
                    </a>
                    {/* |<p className='text-justify px-2'>Hecha con  Abel Castillo.</p> */}
                </div>
            </div>
        </>
    );
};

export default NuestrosMedicosPage;
