import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Importa las imágenes de las banderas
import mex from '../path/to/mex.png';
import usa from '../path/to/usa.png';
import logo from '../path/to/logo_nandy.png';

const NavigationBar = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation(); // Obtén la ruta actual

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const currentLanguage = i18n.language;

    // Función para determinar si el enlace está activo
    const isActive = (path) => location.pathname === path;

    return (
        <nav className='fixed z-50 flex w-full items-center justify-between bg-gray-600 bg-opacity-50 p-4 shadow-md backdrop-blur-lg backdrop-filter'>
            {/* Contenedor del logo ajustado para centrar el botón "Contáctanos" */}
            <div className='flex items-center justify-start'>
                <Link to='/' className='text-xl font-bold'>
                    <img src={logo} alt='Logo' className='h-20' />
                </Link>
            </div>

            {/* Botón "Contáctanos" al centro */}
            {/* <div className='hidden md:flex justify-center flex-grow'>
                <Link to='/contacto' className='flex items-center text-xl border-2 tracking-wide border-blue-500 hover:border-4 text-white hover:text-green-300 font-bold py-2 px-4 rounded'>
                    <FaPhoneAlt />
                    <span className='ml-2'>{t('contactanos')}</span>
                </Link>
            </div> */}

            {/* Menú y selector de idioma */}
            <div className='flex items-center'>
                <div className='flex-grow text-gray-100'>
                    <Link to='/' className={`ml-6 tracking-wide ${isActive('/') ? 'text-green-300' : 'text-white hover:text-green-300'}`}>
                        {t('principal')}
                    </Link>
                    <Link
                        to='/turismo_medico'
                        className={`ml-6 tracking-wide ${isActive('/turismo_medico') ? 'text-green-300' : 'text-white hover:text-green-300'}`}>
                        {t('turismo_medico')}
                    </Link>
                    <Link
                        to='/nuestros_medicos'
                        className={`ml-6 tracking-wide ${isActive('/nuestros_medicos') ? 'text-green-300' : 'text-white hover:text-green-300'}`}>
                        {t('nuestros_medicos')}
                    </Link>
                </div>
                <div className='ml-6'>
                    {currentLanguage !== 'en' && (
                        <button onClick={() => changeLanguage('en')} title='English'>
                            <img src={usa} alt='USA Flag' className='h-10 w-16 object-cover object-center' />
                        </button>
                    )}
                    {currentLanguage !== 'es' && (
                        <button onClick={() => changeLanguage('es')} title='Español'>
                            <img src={mex} alt='Mexico Flag' className='h-10 w-16 object-cover object-center' />
                        </button>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavigationBar;
