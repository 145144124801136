import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import './In18';
import HomePage from './pages/PrincipalPage';
import ServicesPage from './pages/TurismoMedicoPage';
import ContactPage from './pages/NuestrosMedicosPage';
import NavigationBar from './components/NavigationBar'; // Asegúrate de importar tu NavigationBar

function App() {
    return (
        <>
            <NavigationBar />
            <Routes>
                <Route path='/' element={<Outlet />}>
                    <Route index element={<HomePage />} />
                    <Route path='turismo_medico' element={<ServicesPage />} />
                    <Route path='nuestros_medicos' element={<ContactPage />} />
                    {/* Agrega más rutas según sea necesario */}
                </Route>
            </Routes>
        </>
    );
}

export default App;
