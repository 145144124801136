const especialidadesData = [
    {
        nombre: 'Traumatología y ortopedia',
        medicos: [
            {
                nombre: 'Dr. Carlos Hernández',
                foto: 'https://nandyhealthcare-drs.s3.us-east-2.amazonaws.com/Dr+Carlos+Hernandez.jpeg',
                especialidad: 'Traumatología y ortopedia',
                experiencia: 2020,
                educacion: 'Universidad Autónoma de Guadalajara y Universidad de Guanajuato',
                especializaciones: ['Fracturas','prótesis de rodilla','cadera y columna'],
                logros: 'Certificado por el consejo mexicano de Traumatología y Ortopedia',
            },
            {
                nombre: 'Dr. Andrés Esparza',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Traumatología y ortopedia',
                experiencia: 2021,
                educacion: 'IMSS Unidad médica de Alta Especialidad Centro Médico Nacional de Occidente.',
                especializaciones: ['cirugía de traumatología y ortopedia','especialista en columna','investigador', 'emergencias','politraumas','cirugía de mínima invasión'],
                logros: 'Head of Orthopedic Surgeon, teorical professor in Orthopedics Tecnológico de Monterrey. Member and part of the CMOJAL. Member of AOSPINE. Diplomado de columna en Global Spine Diploma Program. Fellowship de cirugía de columna.',
            },
        ],
    },
    {
        nombre: 'Urología',
        medicos: [
            {
                nombre: 'Dr. Fernando Dávila',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Urología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Cardiología',
        medicos: [
            {
                nombre: 'Dr. Roberto Ojeda',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Cardiología',
                experiencia: 2019,
                educacion: 'Medico Cirujano y Partero, Universidad Autónoma de Nayarit. Insti',
                especializaciones: ['Hipertención arterial sistémica.','Cardiopatía isquémica/Infarto agudo al miocardio.','Dolor torácico.','Insuficiencia cardiaca.','Dislipidemia.','Arritmias cardiacas/palpitaciones.','Enfermedades valvulares/Soplos cardiacos.','Valoración de cardiotoxicidad por quimioterapia.','Valoración preoperatoria.','Ecocardiografía de alta especialidad.'],
                logros: 'Alta especialidad en ecografía',
            },
            {
                nombre: 'Dr. Luis Santillán',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Cardiología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Medicina nuclear',
        medicos: [
            {
                nombre: 'Dra. Susana Ruiz',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Medicina nuclear',
                experiencia: 2008,
                educacion: 'Medicina general, Universidad Autónoma de Guadalajara. Especialidad, Centro Médico Nacional La raza. Alta especialidad: INCan',
                especializaciones: ['Especialidad en Medicina Nuclear','Alta especialidad en oncología nuclear'],
                logros: 'Miembro de consejo Mexicano de Medicina Nuclear',
            },
        ],
    },
    {
        nombre: 'Pedriatría',
        medicos: [
            {
                nombre: 'Dr. Javier Ortiz',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Pedriatría',
                experiencia: 2003,
                educacion: 'Hospital Civil de Guadalajara',
                especializaciones: ['Pediatría','patologías pediátricas','Accesos Vasculares','patologías quirúrgicas pediátricas'],
                logros: 'Organizador de CICAP (Ciclo de Conferencia de Actualización de pediatría), Organizador de CICAM (Ciclo de Conferencias de Actualización en Medicina y Talleres.). Ex presidente del colegio de Pediatría de Jalisco, Capítulo Puerto Vallarta (2018-2020). Profesor Adscrito Materia de Gastroenterología de la Universidad de Guadalajara, CUCosta.',
            },
            {
                nombre: 'Dr. Fernando García',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Pedriatría',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
            {
                nombre: 'Dra. Bárbara',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Pedriatría',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Odontología',
        medicos: [
            {
                nombre: 'Dr. Juan Carlos Herrera',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Odontología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
            {
                nombre: 'DDr. Carlos Alvarado',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Odontología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Ginecología',
        medicos: [
            {
                nombre: 'Dr. Juan Landa',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Ginecología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
            {
                nombre: 'Dra. Sandra Llerena',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Ginecología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Dermatología',
        medicos: [
            {
                nombre: '',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Dermatología',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Medicina estética',
        medicos: [
            {
                nombre: 'Dra. Esbeydy Loyola',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Medicina estética',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
            {
                nombre: 'Dr. Moisés Valle',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Medicina estética',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
    {
        nombre: 'Cirugía plástica',
        medicos: [
            {
                nombre: 'Dr. Guillermo Ramos',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Cirugía plástica',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
            {
                nombre: 'Dr. Uriel Muñoz',
                foto: 'https://via.placeholder.com/150',
                especialidad: 'Cirugía plástica',
                experiencia: 0,
                educacion: '',
                especializaciones: [],
                logros: '',
            },
        ],
    },
];

export default especialidadesData;
